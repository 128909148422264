export const VideoJSControls = {
  playToggle: 'playToggle',
  volumePanel: 'volumePanel',
  fullscreenToggle: 'fullscreenToggle',
  currentTimeDisplay: 'currentTimeDisplay',
  timeDivider: 'timeDivider',
  durationDisplay: 'durationDisplay',
  progressControl: 'progressControl',
  remainingTimeDisplay: 'remainingTimeDisplay',
  playbackRateMenuButton: 'playbackRateMenuButton',
  pictureInPictureToggle: 'pictureInPictureToggle',
};
