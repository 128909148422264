/**
 * This component is looks ugly because of deadline and issue with base Video component
 * The VideoJS is now well written, and also miniplayer is very dirty component,
 * This Miniplayer is a temporary solution to be able to use it in tv-schedule page
 * for now but after fixign VideoJs library this one can be refactored to be able to be used
 * in other miniplayer places
 */

import { cn } from '@/lib/utils';
import Image from 'next/image';
import { LongAudioWave } from '../../animations/audio-wave/LongAudioWave';
// import { PlayerFullScreenIcon } from './PlayerFullscreenIcon';
import {
  CloseIcon,
  MuteVolumeIcon,
  PauseIcon,
  PlayIcon,
  UnMuteVolumeIcon,
} from '../../icons';
// import { durationToSeconds, formatDuration } from '@/utils/time/formatDuration';
import { useCallback, useRef, useState } from 'react';
import { VideoJSPlayer } from '../../media/videojs';
import Player from 'video.js/dist/types/player';
import { PlayerFullScreenIcon } from './PlayerFullscreenIcon';

export const WRAPPER_ID = 'mini-player';
export const VIDEO_WRAPPER_ID = 'mini-video-player';
export const MINI_VIDEO_PLAYER_CLASS = 'mini-video-player';
export const MINI_PLAYER_ACTIVE_CLASS = 'active';

interface Props {
  onClose: () => void;
  src: string;
  title: string;
  topic: string;
  description: string;
  background: string;
  isLive: boolean;
  isAudio: boolean;
  isLiveRadio: boolean;
}

export const MiniPlayer = ({
  onClose,
  src,
  title,
  topic,
  description,
  background,
  isLive,
  isAudio,
  isLiveRadio,
}: Props) => {
  // const durationInSeconds =
  //   durationToSeconds(htmlAudioElement?.duration?.toString()) || 0;
  // const percentage =
  //   audioCurrentTime > 0
  //     ? ((durationInSeconds - audioCurrentTime) / durationInSeconds) * 100 || 0
  //     : 0;

  /**These are temporary states used to just rerender the component and not being used */
  const [_, setToggleState] = useState(0);
  const playerRef = useRef<Player | null>(null);

  const handlePlayClick = useCallback(() => {
    if (playerRef.current?.paused?.()) {
      playerRef.current?.play();
      setToggleState(p => p + 1);
    } else {
      playerRef.current?.pause();
      setToggleState(p => p + 1);
    }
  }, []);
  const handleMuteClick = useCallback(() => {
    if (playerRef.current?.muted?.()) {
      playerRef.current?.muted(false);
      setToggleState(p => p + 1);
    } else {
      playerRef.current?.muted(true);
      setToggleState(p => p + 1);
    }
  }, []);

  const isMuted = playerRef?.current?.muted();
  const isPaused = playerRef?.current?.paused();
  return (
    <>
      <div
        id={WRAPPER_ID}
        className={
          'w-[428px] pb-3.5 bg-grey-3 rounded-tl-custom overflow-hidden rounded-tr-custom flex-col justify-start items-start gap-4 fixed right-0 bottom-0 z-10'
        }
      >
        <div
          className={cn(
            'w-full relative flex flex-col justify-start items-start',
            // htmlAudioElement &&
            //   'min-h-60 after:content-[""] after:z-1 after:w-full after:h-full after:absolute after:bottom-0 after:bg-gradient-to-b after:-from-[0%] after:to-[100%] after:from-[#00000000] after:to-[#000000E5] after:rounded-custom',
          )}
        >
          <VideoJSPlayer
            controls={false}
            src={src}
            id={VIDEO_WRAPPER_ID}
            className={'w-full h-full aspect-[428/242]'}
            autoplay
            player={playerRef}
          />

          <div className="w-full opacity-25 bg-black" />
          {background ? (
            <Image
              src={background}
              alt={title || ''}
              className="object-cover relative"
              fill
            />
          ) : null}
          {(isAudio || isLiveRadio) && (
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[2]">
              <LongAudioWave
                color="#FFFFFF"
                isPlaying={!playerRef?.current?.paused()}
              />
            </div>
          )}
          <PlayerFullScreenIcon player={playerRef} />
          {(isLive || isLiveRadio) && (
            <div
              className={cn('w-full h-1', {
                'bg-live': isLive,
                'bg-categories-opinion': isLiveRadio,
              })}
            />
          )}
        </div>
        <div className="w-full h-12 py-px pl-4 pr-6 relative max-w-[428px]">
          <div className="h-12 py-px flex justify-start items-center gap-3">
            <div className="flex flex-col justify-start items-start gap-2">
              <div className="flex justify-start items-center gap-2">
                <div
                  className={
                    'text-grey-11 text-lg font-medium font-notoserif truncate w-72'
                  }
                >
                  {title}
                </div>
              </div>
              <div className="w-72 self-stretch text-grey-11 text-xs2 font-normal font-notosans truncate">
                {description}
              </div>
            </div>
          </div>
          <div className="flex justify-start items-center gap-4 absolute ltr:right-0 rtl:left-0 top-1/2 -translate-y-1/2 pe-4 ps-3 h-full bg-grey-3">
            {isMuted ? (
              <UnMuteVolumeIcon
                onClick={handleMuteClick}
                className="cursor-pointer"
              />
            ) : (
              <MuteVolumeIcon
                onClick={handleMuteClick}
                className="cursor-pointer"
              />
            )}
            {isPaused ? (
              <PlayIcon onClick={handlePlayClick} className="cursor-pointer" />
            ) : (
              <PauseIcon onClick={handlePlayClick} className="cursor-pointer" />
            )}
          </div>
        </div>
        <div
          className={'absolute right-3 top-3 cursor-pointer'}
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon color={'white'} />
        </div>
      </div>

      {isAudio && (
        //Mobile audio view
        <div className="fixed bottom-0 w-full bg-white md:hidden flex flex-col gap-2 p-2.5 z-10">
          <div className="flex flex-row gap-1.5">
            {background ? (
              <div className="relative md:hidden flex items-center justify-center bg-black rounded-custom w-full max-w-[62px] h-[62px]">
                <Image
                  src={background}
                  alt={title || ''}
                  className="object-cover relative rounded-custom aspect-square opacity-50"
                  width={62}
                  height={62}
                />
                {isPaused ? (
                  <PlayIcon
                    color="white"
                    onClick={handlePlayClick}
                    className="cursor-pointer absolute"
                  />
                ) : (
                  <PauseIcon
                    color="white"
                    onClick={handlePlayClick}
                    className="cursor-pointer absolute"
                  />
                )}
              </div>
            ) : null}

            <div className="flex flex-col gap-1.5 w-full">
              <div className="text-primary-3 font-sf text-xs uppercase font-extrabold">
                {topic}
              </div>
              <div className="text-grey-13 font-notoserif text-sm2 font-bold line-clamp-2">
                {title}
              </div>
            </div>

            <div className={'cursor-pointer'} onClick={onClose}>
              <CloseIcon color={'black'} />
            </div>
          </div>
          {/* {htmlAudioElement && (
            <div className="flex flex-row items-center justify-center gap-1.5 font-sf text-xs text-black">
              {formatDuration(Math.round(audioCurrentTime)) || '00:00'}
              <div className="w-full bg-grey-6 h-1 relative">
                <div
                  className="bg-grey-10 h-1 absolute left-0 top-0 transition-[width] ease-linear"
                  style={{ width: `${isAudioEnded ? 100 : percentage}%` }}
                />
              </div>
              {formatDuration(htmlAudioElement.duration || '')}
            </div>
          )} */}
        </div>
      )}
    </>
  );
};
