'use client';

import { MiniPlayer } from '@/app/components/common/mini-player/MiniPlayer';
import { PlayIcon } from '@/app/components/icons';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

interface Props {
  src: string;
  title: string;
  description: string;
  background: string;
}

export const WatchLiveButton = ({ title, description, src }: Props) => {
  const t = useTranslations();
  const [showLiveTV, setShowLiveTV] = useState(0);

  return (
    <>
      <button
        onClick={() => {
          setShowLiveTV(p => p + 1);
          setTimeout(() => {
            const event = new Event('custom', {
              bubbles: true,
              cancelable: true,
            });

            // You can dispatch the event on any element you want (e.g., the window or a specific div)
            window.dispatchEvent(event);
          }, 1000);
        }}
        className={
          'py-2 pl-3 pr-4 flex flex-row gap-2 w-fit bg-categories-live rounded'
        }
      >
        <PlayIcon className={cn('text-white', 'rtl:rotate-180')} />

        <span className="my-auto font-sf text-white font-bold text-xs2 leading-normal tracking-[0.65px]">
          {t('watch-live')}
        </span>
      </button>
      {showLiveTV ? (
        <MiniPlayer
          topic=""
          src={src}
          title={title}
          description={description}
          background={''}
          isLive
          isAudio={false}
          isLiveRadio={false}
          onClose={() => {
            setShowLiveTV(0);
          }}
        />
      ) : null}
    </>
  );
};
