import { VideoJSControls, VideoJSPlayer } from '@/app/components/media/videojs';
import { cn } from '@/lib/utils';
import { ComponentProps } from 'react';

interface Props extends ComponentProps<typeof VideoJSPlayer> {
  src: string;
  containerClassName?: string;
}
const hideControls = [
  VideoJSControls.pictureInPictureToggle,
  VideoJSControls.volumePanel,
  VideoJSControls.fullscreenToggle,
  VideoJSControls.remainingTimeDisplay,
] as (keyof typeof VideoJSControls)[];

const showControls = [
  VideoJSControls.currentTimeDisplay,
  VideoJSControls.durationDisplay,
  VideoJSControls.timeDivider,
  VideoJSControls.playToggle,
] as (keyof typeof VideoJSControls)[];

export const VideoJSPlayerDefault = ({
  className,
  containerClassName,
  ...props
}: Props) => {
  return (
    <div className={cn('video-js-player-default', containerClassName)}>
      <VideoJSPlayer
        className={cn(
          'object-contain w-full lg:h-[760px] rounded-custom',
          className,
        )}
        hidePlaybackRates
        controls
        preload={'metadata'}
        hideControls={hideControls}
        showControls={showControls}
        {...props}
      />
    </div>
  );
};
