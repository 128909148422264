'use client';

import { useGetChannelPref } from '@/providers/ChannelProvider';
import Link from 'next/link';
import { ComponentProps } from 'react';
import { getTVShowLink } from './link-utils';

interface Props extends Omit<ComponentProps<typeof Link>, 'href'> {
  slug?: string | null;
}

const TVShowLinkLink = ({ slug, ...props }: Props) => {
  const channelPref = useGetChannelPref();
  if (!slug) {
    return (
      <div style={props.style} className={props.className}>
        {props.children}
      </div>
    );
  }
  return <Link {...props} href={getTVShowLink(channelPref, slug as string)} />;
};

export const TVShowLink = (props: Props) => {
  return <TVShowLinkLink {...props} />;
};
