'use client';

import React, {
  CSSProperties,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import Player from 'video.js/dist/types/player';
import videojs from 'video.js';
import { VideoJSControls } from '@/app/components/media/videojs';

import 'video.js/dist/video-js.css';
import './videojs.css';
import { cn } from '@/lib/utils';

export type VideoPlayerProps = {
  src: string;
  poster?: string;
  controls?: boolean;
  autoplay?: boolean;
  muted?: boolean;
  fluid?: boolean;
  preload?: 'auto' | 'none' | 'metadata';
  width?: string | number;
  height?: string | number;
  showControls?: (keyof typeof VideoJSControls)[];
  hideControls?: (keyof typeof VideoJSControls)[];
  bigPlayButton?: boolean;
  disablePictureInPicture?: boolean;
  bigPlayButtonCentered?: boolean;
  onReady?: (player: Player) => void;
  onPlay?: (currentTime: number) => void;
  onPause?: (currentTime: number) => void;
  onTimeUpdate?: (currentTime: number) => void;
  onSeeking?: (currentTime: number) => void;
  onSeeked?: (startPosition: number, endPosition: number) => void;
  onEnd?: () => void;
  playbackRates?: number[];
  hidePlaybackRates?: boolean;
  className?: string;
  id?: string;
  player?: MutableRefObject<Player | null>;
  style?: CSSProperties;
};

const VideoJSPlayer = ({
  src,
  poster,
  controls = true,
  autoplay = false,
  muted,
  fluid,
  preload = 'none',
  width,
  height,
  showControls = [],
  hideControls = [],
  bigPlayButton = false,
  bigPlayButtonCentered = true,
  disablePictureInPicture = false,
  onReady = () => {},
  onPlay = () => {},
  onPause = () => {},
  onTimeUpdate = () => {},
  onSeeking = () => {},
  onSeeked = () => {},
  onEnd = () => {},
  playbackRates = [0.5, 1, 1.5, 2],
  hidePlaybackRates = false,
  className = '',
  id,
  player,
  style,
}: VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<Player | null>(null);
  const playerOptions = useMemo(() => {
    const options: typeof videojs.options = {
      controls,
      autoplay,
      muted,
      fluid,
      preload,
      width,
      height,
      bigPlayButton,
      disablePictureInPicture,
      controlBar: {},
      playbackRates: hidePlaybackRates ? undefined : playbackRates,
    };

    showControls.forEach(controlName => {
      options.controlBar = {
        ...options.controlBar,
        [controlName]: true,
      };
    });

    hideControls.forEach(controlName => {
      options.controlBar = {
        ...options.controlBar,
        [controlName]: false,
      };
    });
    return options;
  }, [
    autoplay,
    bigPlayButton,
    controls,
    disablePictureInPicture,
    height,
    hideControls,
    hidePlaybackRates,
    playbackRates,
    preload,
    showControls,
    width,
    fluid,
    muted,
  ]);

  const addEvents = useCallback(
    (player: Player) => {
      player.on('play', () => onPlay(player.currentTime() || 0));
      player.on('pause', () => onPause(player.currentTime() || 0));
      player.on('timeupdate', () => onTimeUpdate(player.currentTime() || 0));
      player.on('seeking', () => onSeeking(player.currentTime() || 0));
      player.on('seeked', () => {
        const completeTime = Math.floor(player.currentTime() || 0);
        onSeeked(player.currentTime() || 0, completeTime);
      });
      player.on('ended', () => onEnd());
    },
    [onEnd, onPause, onPlay, onSeeked, onSeeking, onTimeUpdate],
  );

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    function onMouseOut() {
      if (playerRef.current) {
        playerRef.current.addClass('vjs-user-inactive');
      }
    }
    function onMouseIn() {
      if (playerRef.current) {
        playerRef.current.removeClass('vjs-user-inactive');
      }
    }

    if (playerRef.current) {
      addEvents(playerRef.current);
      return;
    }

    playerRef.current = videojs(videoRef.current, playerOptions, function () {
      onReady(this);
    });
    if (player) {
      player.current = playerRef.current;
    }
    if (playerRef.current && playerOptions.controls) {
      playerRef.current.on('mouseout', onMouseOut);
      playerRef.current.on('mouseover', onMouseIn);
    }

    addEvents(playerRef.current);

    return () => {
      if (playerRef.current && playerOptions.controls) {
        playerRef.current.off('mouseout', onMouseOut);
        playerRef.current.off('mouseover', onMouseIn);
      }
    };
  }, [addEvents, onReady, playerOptions]);

  useEffect(() => {
    const player = playerRef.current;

    if (player) {
      player.src(src);
      if (poster) {
        player.poster(poster);
      }
    }
  }, [src, poster]);

  return (
    <video
      id={id}
      src={src}
      ref={videoRef}
      className={cn(
        `video-js vjs-default-skin`,
        {
          'vjs-big-play-centered': bigPlayButtonCentered,
          'with-timer-controls': showControls.some(
            c => c === VideoJSControls.currentTimeDisplay,
          ),
        },
        className,
      )}
      style={style}
    />
  );
};

export { VideoJSPlayer };
