'use client';

import { getContentDurationString } from '@/common/utils';
import { Content } from '@/lib/model';
import { MutableRefObject, useEffect, useState } from 'react';
import Player from 'video.js/dist/types/player';

export const ContentDurationClient = ({
  player,
}: {
  content: Content;
  player: MutableRefObject<Player | null>;
}) => {
  const [duration, setDuration] = useState<string>();
  useEffect(() => {
    if (player && player.current) {
      player.current.preload('metadata');
      player.current.on('loadedmetadata', () => {
        setDuration(getContentDurationString(player.current?.duration()));
      });
    }
  }, []);

  return <>{duration ? <div className="text-white">{duration}</div> : null}</>;
};
